import React from 'react'
import Juror from '../../components/juror'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from './leftMenu'

const Jury = () => {
  return (
    <Layout>
    <SEO title="National Student Essay Competition | Jury" />

    <div className="subpageContainer">

      <LeftMenu active="jury" />
      <div className="body">
         
        <h2 style={{marginTop: '55px'}}>NATIONAL STUDENT ESSAY COMPETITION JURY</h2>
        <div className="jurors">
        <Juror name="Chair: Philip G. McCarthey" title="Trustee" institution="McCarthey Family Foundation" />
        <Juror name="Robert Austin" title="Education Specialist" institution="Utah State Office of Education" />
        <Juror name="Richard Badenhausen" title="Honors College Dean" institution="Westminster College" />
        <Juror name="Leslie Francis" title="Emery Distinguished Professor of Law and Distinguished Professor of Philosophy" institution="University of Utah" />
        <Juror name="Jodi Graham" title="Executive Director" institution="Utah Humanities" />
        <Juror name="Heidi Legg" title="Host of SWING STATE podcast, Founder of TheEditorial.com" institution="media theorist" />
        <Juror name="Thomas A. Lund" title="Professor, College Of Law" institution="University of Utah" />
        <Juror name="Mark Matheson" title="Professor, English" institution="University of Utah" />
        <Juror name="Chris Matthews" title="Author, Journalist, Hosted “Hardball,” Lecturer at Fulbright University" institution="" />
        <Juror name="Carmen Nobel" title="Program Director" institution="Journalist’s Resource, Harvard Kennedy School Shorenstein Center on Media, Politics and Public Policy" />
        <Juror name="Bill Plante" title="Veteran journalist, former CBS News Senior White House Correspondent" institution="" />
        <Juror name="David Plazas" title="Director of Opinion and Engagement, The Tennessean" institution="" />
        <Juror name="Marjorie Pritchard" title="Op Ed Page Editor" institution="Boston Globe" />
        <Juror name="Noella Sudbury, J.D." title="Founder" institution="Sudbury Consulting" />
        <Juror name="Sylvia Torti" title="Honors College Dean" institution="University of Utah" />
        
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default Jury
