import React from "react"

const Juror = props => {
  const { name, title, institution } = props
  return (
    <div className="juror">
      <div className="name">{name}</div>
      <div className="title">{title}</div>
      <div className="institution">{institution}</div>
    </div>
  )
}

export default Juror